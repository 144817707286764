.popup {
  position: fixed;
  transform: translate(-50%, -50%);
  background-color: white;
  border: 1px solid #ccc;
  padding: 20px;
  z-index: 1000;
}

.options {
  margin-bottom: 20px;
}

.option-button {
  flex: 1;
  padding: 10px;
  margin: 5px;
  border: 1px solid #ccc;
  cursor: pointer;
}

.option-button.selected {
  background-color: #007BFF;
  color: white;
}

.cancel-button {
  margin-right: 6px;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  text-align: center;
}

.confirm-button {
  padding: 10px 20px;
  border: none;
  background-color: #28a745;
  color: white;
  cursor: pointer;
  margin-left: 6px;
}

.popup p {
  font-size: 20px;
}
