.btn-group {
  position: relative;
}

.btn-group button {
  border: 1px solid #445; /*&*/ /* Green border */
  padding: 8px; /* Some padding */
  cursor: pointer; /* Pointer/hand icon */
  float: left; /* Float the buttons side by side */
  background-color: white;
  color: #445;/*&*/ /* Mid grey font color */
  transition: background-position 0.5s, color 0.5s, background-color 0.5s; /* Smooth transition for hover effects */
}

.btn-group button:nth-child(1) {
  background-color: rgb(239, 239, 239);
}

.btn-group button:nth-child(2) {
  background-color: #445; /* rgba(131, 131, 131, 1) */
  color: white;
  border-top-left-radius: 1px;
  border-bottom-left-radius: 1px;
}

.btn-group button:not(:nth-child(3)) {
  border-right: none; /* Prevent double borders */
}

.btn-group button:nth-child(3) {
  border-top-right-radius: 1px;
  border-bottom-right-radius: 1px;
}

/* Clear floats (clearfix hack) */
.btn-group:after {
  content: "";
  clear: both;
  display: table;
}

/* Add a background color on hover */
.btn-group .option:hover, .btn-group .option.selected {
  background-position: -100% 0;
  background-color: rgb(173 117 187);
  color: white; /* Change font color on hover for better visibility */
}