.hex.shiny-effect {
  animation: shiny 2s infinite;
  cursor: pointer;
}

div.shiny-font {
  animation: color-change 2s infinite;
  min-width: 33px;
  font-weight: bolder;
  letter-spacing: -1px;
  writing-mode: tb;
  transform: rotate(-180deg);
}

.regular-font {
  font-weight: bolder;
  letter-spacing: -1px;
  min-width: 33px;
  writing-mode: tb;
  transform: rotate(-180deg);
}
.regular-font.peacha {
  color: #cdcdcd;
}
.regular-font.browna {
  color: #454545;
}
.regular-font.beigea {
  color: #787878;
}

/* these go with the hex classes to flash a chat vote / move */
.from {
  animation: animateFromKeyframes 1.3s ease-in-out;
}

.to {
  animation: animateToKeyframes 1.3s ease-in-out;
}

@keyframes color-change {
  0%, 100% {
    color: #efefef;
  }
  50% {
    color: #101010;
  }
}

@keyframes shiny {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

@keyframes animateFromKeyframes {
  0%, 20%, 60%, 100% { opacity: 1; }
  40% { opacity: 0.1; }
}

@keyframes animateToKeyframes {
  0%, 60%, 100% { opacity: 1; } 
  80% { opacity: 0.1; } 
}

div {
  user-select: none;
}

.purple-black {
  color: #140014;
}

/* piece size - the actual board and pieces scale on screen sizes so this is fixed */
.hex .middle span {
  font-size: 69px;
  position: relative;
  top: -9px;
  transform: rotate(-90deg);
  writing-mode: tb;
}

/* honeycomb stuffs */

.hex {
  float: left;
  margin-left: 3px;
  margin-bottom: -26px;
  transition: border 1.5s ease;
  will-change: opacity;
}

.hex .top {
  width: 0;
  border-left: 52px solid transparent;
  border-right: 52px solid transparent;
}

.hex .top.beige {

  border-bottom: 30px solid #D9BFFF;
}

.hex .top.peach {
  border-bottom: 30px solid rgb(173 117 187); /* #8E44AD; */
}

.hex .top.brown {
  border-bottom: 30px solid #B398D6;
}

.hex .middle {
  width: 104px;
  height: 60px;
  margin-top: -1px;
  border-radius: 3px;
}

.hex .middle.beige {
  background: #D9BFFF;
}

.hex .middle.peach {
  background: rgb(173 117 187); /* #8E44AD; */
}

.hex .middle.brown {
  background: #B398D6;
}

.hex .bottom {
  width: 0;
  border-left: 52px solid transparent;
  border-right: 52px solid transparent;
  margin-top: -1px;
}

.hex .bottom.peach {
  border-top: 30px solid rgb(173 117 187); /* #8E44AD; */
}

.hex .bottom.beige {
  border-top: 30px solid #D9BFFF;
}

.hex .bottom.brown {
  border-top: 30px solid #B398D6;
}

.hex-row {
  clear: left;
}

.hex-row.even {
  margin-left: 52px;
}

.hex-row {
  display: flex;
  flex-direction:row;
  clear: left;
}

.hex-row.even {
  margin-left: 52px;
}
