@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');

button.newGame {
  position: relative;
  margin: 0 auto;
  left: 6px;
  top: -2px;
  background-color: white; /* Green background */
  border: 1px solid #445; /*&*/ /* Green border */
  color: #445;/*&*/ /* White text */
  padding: 1.5px 13.4px; /* Some padding */
  cursor: pointer; /* Pointer/hand icon */
}

button.volume {
  position: relative;
  margin: 0 auto;
  left: 12px;
  top: -2px;
  background-color: white; /* Green background */
  border: 1px solid #445; /*&*/ /* Green border */
  color: #445;/*&*/ /* White text */
  padding: 1.5px 13.4px; /* Some padding */
  cursor: pointer; /* Pointer/hand icon */
}

#findme {
  font-size: 18px;
  font-family: 'Press Start 2P', monospace;
  position: absolute;
  top: 20px;
  /* Set the gradient background */
  background: linear-gradient(to bottom, mediumpurple, #8E44AD);

  /* Clip the background to the text */
  -webkit-background-clip: text;
  background-clip: text;

  /* Make the text color transparent */
  -webkit-text-fill-color: transparent;
  color: transparent; /* Fallback: Some browsers do not support this feature */

}